<template>
  <div :class="`code ${codeColor}`">
    <div class="person">
      <img class="avatar" :src="user.avatarImage">
      <div>
        <div class="name">{{data.parentPO.xm}}({{parentTypeList[data.parentPO.parentType]}})</div>
        <div class="addr">
          <img :src="`${publickPath}static/img/code/icon-postion.png`">
          <div class="addr-name">{{data.schoolName}}</div>
          <van-icon class="arrow" name="arrow" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="student-content" v-if="studentList.length === 1">
        <div class="s-name">{{data.studentPO.name}}</div>
        <div>{{data.teacher.username}}老师</div>
        <div>{{data.schoolGradePO.grade + data.schoolGradePO.gradeClass + '班'}}</div>
      </div>
      <div class="box">
        <div class="student-list" v-if="studentList.length === 2">
          <div
            class="student-item"
            v-for="item in studentList"
            :key="item.studentPO.id">
            <div class="s-name">{{item.studentPO.name}}</div>
            <div>{{item.teacher.username}}老师</div>
            <div>{{item.schoolGradePO.grade + item.schoolGradePO.gradeClass + '班'}}</div>
          </div>
        </div>
        <div class="student-box"  v-if="studentList.length > 2">
          <div class="student-list s-more">
            <div
              class="student-item"
              v-for="item in studentList"
              :key="item.studentPO.id">
              <div class="s-name">{{item.studentPO.name}}</div>
              <div>{{item.teacher.username}}老师</div>
              <div>{{item.schoolGradePO.grade + item.schoolGradePO.gradeClass + '班'}}</div>
            </div>
          </div>
        </div>
        <div class="code-content">
          <div :class="{'time': true, 'max-time': studentList.length === 1}">{{currentTime}}</div>
          <div ref="qrcode" class="code-img" @click="showBigCode = true"></div>
          <div class="hide" v-if="codeColor !='gray'">已对接健康码平台，凭此二维码可正常出入请主动出示并配合检查。</div>
          <div class="hide" v-if="codeColor == 'gray'">健康码获取失败</div>
        </div>
      </div>
    </div>
    <div class="modle" v-show="showBigCode"  @click="showBigCode = false">
      <div class="big-code-box">
        <div ref="bigQrcode" class="big-code-img"></div>
      </div>
    </div>
  </div>  
</template>

<script>
import QRCode from 'qrcodejs2'
import md5 from '../../utils/md5'
import { getJkm, getParentStudent, setStudentVisitor } from "../../utils/codeRequest"
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return{
      publickPath : process.env.BASE_URL,
      user: {},
      codeColor: 'gray',
      colorList: {
        'green': '#27AD64',
        'red': '#DE4040',
        'orange': '#DB7E23',
        'gray': '#333'
      },
      parentTypeList: {
        0: '父亲',
        1: '母亲',
        2: '爷爷',
        3: '奶奶',
        4: '外公',
        5: '外婆',
        99: '其他'
      },
      timer: null,
      currentTime: this.$tools.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      studentList: [],
      data: {
        parentPO: {},
        studentPO: {},
        schoolGradePO: {},
        teacher: {}
      },
      showBigCode: false,
      zoneId: this.$route.query.zoneId, // 学校zoneId
      jkmStr: '',
    }
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["returnRoleData"]),
  },
  mounted() {
    this.user = this.returnRoleData.userPO;
    this.getJkm(); // 获取健康码
    this.getParentStudent();
    this.timer = setInterval(this.showCurrentTime, 1000);
  },
  methods: {
    // 获取当前时间
    showCurrentTime() {
      this.currentTime = this.$tools.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
    },
    // 获取家长孩子信息
    getParentStudent() {
      getParentStudent({userId: this.user.id, zoneId: this.zoneId}).then(res => {
        if (res.code === 'SUCCESS') {
          this.studentList = res.data
          this.data = this.studentList[0]
          let punchData = {
            studentIds: this.studentList.map(item => item.studentPO.id),
            parentId: this.studentList[0].parentPO.id,
            parentSfzh: this.studentList[0].parentPO.sfzh
          }
          this.punchCard(punchData);
          this.setColor();
          this.createQrcode();
          console.log('student', res.data)
        }
      })
    },
    // 学生接送打卡(生成访问记录)
    punchCard(data) {
      setStudentVisitor(data).then(res => {
        console.log('pushCard', res)
      })
    },
    // 获取健康码
    getJkm: function() {
      getJkm({idCard: this.user.certificateNum}).then(res => {
        if (res.code == 'SUCCESS') {
          let data = res.data
          if (data.code === 1) {
            this.jkmStr = data.data;
          }
        }
        // this.getParentStudent();
      })
    },
    // 设置颜色
    setColor() {
      if (this.jkmStr === '') {
        this.codeColor = 'green';
      } else if (this.jkmStr === '绿码') {
        this.codeColor = 'green';
      } else if (this.jkmStr === '黄码') {
        this.codeColor = 'orange';
      } else {
        this.codeColor = 'red';
      }
    },
    // 生成二维码
    createQrcode() {
      this.$refs.qrcode.innerHTML = '';
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: window.location.href,
        width: 157,
        height: 157,
        colorDark: this.colorList[this.codeColor],
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      })
      this.$refs.bigQrcode.innerHTML = '';
      let bigQrcode = new QRCode(this.$refs.bigQrcode, {
        text: window.location.href,
        width: 280,
        height: 280,
        colorDark: this.colorList[this.codeColor],
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>

<style lang="less" scoped>
  .code{
    position: relative;
    height: 100%;
    font-size: .746667rem /* 14/18.75 */;
    background: #fff;
    .person{
      display: flex;
      height: 11.733333rem /* 220/18.75 */;
      padding: 1.6rem /* 30/18.75 */ 1.066667rem /* 20/18.75 */ 3.2rem /* 60/18.75 */;
      color: #fff;
      .avatar{
        width: 3.52rem /* 66/18.75 */;
        height: 3.52rem /* 66/18.75 */;
        border-radius: 50%;
        margin-right: .853333rem /* 16/18.75 */;
      }
      .name{
        font-size: 1.493333rem /* 28/18.75 */;
      }
      .addr{
        margin-top: .533333rem /* 10/18.75 */;
        img{
          width: .72rem /* 13.5/18.75 */;
          height: .8rem /* 15/18.75 */;
          vertical-align: middle;
          margin-right: .24rem /* 4.5/18.75 */;
        }
        .addr-name{
          display: inline-block;
          vertical-align: middle;
          max-width: 10.666667rem /* 200/18.75 */;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .arrow{
          vertical-align: middle;
          margin-left: .32rem /* 6/18.75 */;
        }
      }
    }
    .content{
      position: absolute;
      width: 18.133333rem /* 340/18.75 */;
      top: 6.133333rem /* 115/18.75 */;
      left: 0;
      right: 0;
      margin: 0 auto;
      .student-content{
        display: flex;
        justify-content: space-between;
        color: #fff;
        font-size: .8rem /* 15/18.75 */;
        margin-bottom: .8rem /* 15/18.75 */;
        padding: 0 1.6rem /* 30/18.75 */;
        .s-name{
          font-size: .906667rem /* 17/18.75 */;
        }
      }
    }
    .box{
      // width: 18.133333rem /* 340/18.75 */;
      width: 100%;
      min-height: 23.2rem /* 435/18.75 */;
      // height: 24.746667rem /* 464/18.75 */;
      background: #fff;
      border-radius: .8rem /* 15/18.75 */;
      box-shadow: .106667rem /* 2/18.75 */ 0 .533333rem /* 10/18.75 */ rgba(4, 0, 0, .1);
      padding-bottom: 1.066667rem /* 20/18.75 */;
      .code-content{
        text-align: center;
        padding-top: 1.333333rem /* 25/18.75 */;
        .time{
          width: 10.24rem /* 192/18.75 */;
          margin: 0 auto;
          font-size: 1.76rem /* 33/18.75 */;
          // font-weight: bold;
          line-height: 1.3;
          color: #333;
          letter-spacing: .106667rem /* 2/18.75 */;
        }
        .max-time{
          width: 13.6rem /* 255/18.75 */;
          font-size: 2.133333rem /* 40/18.75 */;
          margin-top: .8rem /* 15/18.75 */;
        }
        .code-img{
          width: 157px;
          height: 157px;
          margin: 1.333333rem /* 25/18.75 */ auto;
        }
        .hide{
          width: 14.826667rem /* 278/18.75 */;
          margin: 0 auto;
          color: #666;
          line-height: 1.8;
        }
      }
      .student-box{
        height: 5.6rem /* 105/18.75 */;
        width: 100%;
        padding-right: .533333rem /* 10/18.75 */;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .student-list{
        display: flex;
        height: 5.6rem /* 105/18.75 */;
        .student-item{
          position: relative;
          width: 50%;
          text-align: center;
          font-size: .8rem /* 15/18.75 */;
          color: rgba(51, 51, 51, .6);
          padding: 1.066667rem /* 20/18.75 */ 0;
          div{
            margin-bottom: .266667rem /* 5/18.75 */;
          }
          &:first-child::after{
            content: '';
            position: absolute;
            width: .026667rem /* 0.5/18.75 */;
            height: 2.666667rem /* 50/18.75 */;
            background: #ebebeb;
            right: 0;
            top: 1.493333rem /* 28/18.75 */;
          }
          .s-name{
            color: #333;
            font-size: .906667rem /* 17/18.75 */;
          }
        }
        &.s-more{
          padding-left: .533333rem /* 10/18.75 */;
          padding-top: .906667rem /* 17/18.75 */;
          width:fit-content;
          width:-webkit-fit-content;
          width:-moz-fit-content;
          .student-item{
            width: 6.506667rem /* 122/18.75 */;
            height: 4.426667rem /* 83/18.75 */;
            flex-shrink: 0;
            background: #F6F7FB;
            border-radius: .8rem /* 15/18.75 */;
            margin-right: .533333rem /* 10/18.75 */;
            padding: .266667rem /* 5/18.75 */ 0 0;
            &:first-child::after{
              display: none;
            }
          }
        }
      }
    }
    &.green{
      .person {
        background-color: #27AD64;
      }
    }
    &.red{
      .person {
        background-color: #DE4040;
      }
    }
    &.orange{
      .person {
        background-color: #DB7E23;
      }
    }
    &.gray{
      .person {
        background-color: #2B71FD;
      }
    }
    .modle{
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      background: rgba(0, 0, 0, .8);
      .big-code-box{
        width: 300px;
        height: 300px;
        margin: 50% auto;
        background: #fff;
        overflow: hidden;
      }
      .big-code-img{
        width: 280px;
        height: 280px;
        margin: 10px auto;
      }
    }
  }
  
</style>